import React from 'react';
import PropTypes from 'prop-types';
import { t } from '../../../domain/services/configService';
import InlinePromos from './InlinePromos';
import { ParagraphWrapper } from '../ParagraphWrapper';

const InlinePromosParagraphInner = ParagraphWrapper(props => {
  // "promocards" is the old name stored in JSON, keep it until all contents are reexported.
  const promoCards = props.promoCards || props.promocards;
  const data = promoCards.map((promoCard, index) => {
    if (index < 3 && promoCard.entity.card.entity) {
      const returnData = {};

      if (promoCard.entity?.card?.entity?.promoImage?.[0]?.entity?.mediaImage) {
        returnData.imageEntity =
          promoCard.entity.card.entity.promoImage?.[0]?.entity;
      } else if (promoCard.entity?.card?.entity?.image?.entity?.mediaImage) {
        returnData.imageEntity = promoCard.entity.card.entity.image.entity;
      }
      returnData.title = promoCard.entity.card.entity.title;
      if (
        promoCard.entity?.card?.entity?.promoTitle &&
        promoCard.entity.card.entity.promoTitle !== ''
      ) {
        returnData.title = promoCard.entity.card.entity.promoTitle;
      }
      if (promoCard.entity?.card?.entity?.link?.url?.path) {
        returnData.url = promoCard.entity.card.entity.link.url.path;
        returnData.openInNewTab = promoCard.entity.card.entity.displayInNewTab;
      } else {
        returnData.url = promoCard.entity.card.entity.url.path;
        returnData.openInNewTab = false;
      }
      returnData.header = t('Read');
      if (promoCard.entity?.header) {
        returnData.header = t(promoCard.entity.header);
      }
      returnData.icon = 'read';
      if (promoCard.entity?.promoIcon) {
        returnData.icon = promoCard.entity.promoIcon;
      }
      returnData.cta = t('Read Story');
      if (promoCard.entity?.cta) {
        returnData.cta = promoCard.entity.cta;
      }
      returnData.sponsor =
        promoCard.entity?.card?.entity?.branded &&
        promoCard.entity?.card?.entity?.sponsoredText;
      return returnData;
    }
    return null;
  });
  return <InlinePromos data={data} />;
});

InlinePromosParagraphInner.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  promoCards: PropTypes.array.isRequired
};

export const InlinePromosParagraph = ParagraphWrapper(
  InlinePromosParagraphInner
);
