import React from 'react';
import { WithIcon } from './WithIcon';

export const IconLook = WithIcon(
  'Look',
  '0 0 30 30',
  <>
    <path d="M21.75,10.88V23.12H4.13V10.88H21.75m1.5-1.5H2.63V24.62H23.25V9.38Z" />
    <polyline
      style={{
        fill: 'none',
        stroke: '#000',
        strokeWidth: '1.5px',
        strokeMiterlimit: '10'
      }}
      points="7.75 5.38 27.38 5.38 27.38 19.62"
    />
  </>
);
