import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { SmartLink } from '../../SmartLink';
import { IconRead } from '../../Icon/IconRead';
import { IconWatch } from '../../Icon/IconWatch';
import { IconLook } from '../../Icon/IconLook';
import { Image } from '../../Image';
import { facePaint } from '../../../domain/theme/utils/facePaint';
import { theme } from '../../../domain/theme';

const ngInlinePromo = css`
  border: 1px solid #e7e7e7;
  align-items: stretch;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;

  & a {
    color: inherit;
  }

  ${theme.mq.small_desktop} {
    & {
      flex: 1;
      margin-right: 8px;
      flex-direction: column;
      max-width: 215px;
    }
  }
`;

const ngInlinePromoCont = css`
  padding: 15px 15px 15px 20px;
  flex: 1;
  position: relative;

  ${theme.mq.small_desktop} {
    & {
      padding: 15px;
    }
  }
`;

const ngInlinePromoContainer = css`
  ${theme.mq.small_desktop} {
    & {
      display: flex;
      flex-direction: row;
      margin-bottom: 30px;
    }
  }
`;

const ngInlinePromoCta = css`
  display: none;
  ${theme.font.family('regularWeb')};
  font-weight: 400;
  text-transform: uppercase;
  font-size: 0.75rem;
  font-weight: 400;
  color: #000;
  position: absolute;
  bottom: 5px;
  left: 12px;

  & svg {
    width: 1em;
    height: 1em;
    margin-right: 10px;
  }

  ${theme.mq.small_desktop} {
    & {
      font-size: 0.8125rem;
      display: block;
    }
  }
`;

const ngInlinePromoHeader = css`
  ${theme.font.family('boldWeb')};
  font-weight: 400;
  text-transform: uppercase;
  font-size: 0.875rem;
  font-weight: 600;
  color: #9f9f9f;
  margin-bottom: 10px;
  letter-spacing: 0.2em;

  ${theme.mq.small_desktop} {
    & {
      font-size: 0.75rem;
      margin-bottom: 15px;
    }
  }
`;

const ngInlinePromoSponsor = css`
  ${theme.font.family('regularWeb')};
  font-weight: 400;
  text-transform: uppercase;
  font-size: 0.75rem;
  font-weight: 400;
  color: #9f9f9f;
  margin-top: -12px;
  margin-bottom: 10px;
  letter-spacing: 0.2em;
`;

const ngInlinePromoTitle = css`
  position: relative;
  ${theme.font.family('boldWeb')};
  font-weight: 400;
  letter-spacing: 0.04em;
  color: #000;
  font-size: 0.875rem;

  ${theme.mq.small_desktop} {
    & {
      font-size: 1rem;
      line-height: 1.125rem;
      margin-bottom: 20px;
      position: relative;
      word-break: break-word;
    }
  }
`;
const ngInlinePromoImage = css`
  width: 100px;
  height: 100%;
  background-position: center;
  background-size: cover;
  margin-right: 5px;
  overflow: hidden;

  & .resp-img-cntr {
    display: inline;
    height: auto;
  }

  ${theme.mq.small_desktop} {
    & {
      width: 100%;
      height: 0;
      padding-bottom: 75%;
    }
  }
`;
const styles = {
  contentVisibility: css`
    content-visibility: auto;
    ${facePaint({
      'contain-intrinsic-size': ['0 476px', '0 587px', '0 488px', '0 520px']
    })};
  `
};
const InlinePromos = ({ data }) => {
  return (
    <div css={[styles.contentVisibility, ngInlinePromoContainer]}>
      {data
        // Remove null promos
        .filter(promo => promo)
        .map(promo => {
          const charMax = 64;
          const promoTitle = promo.title || '';
          const truncatedTitle =
            promoTitle.length > charMax + 3
              ? `${promoTitle.substr(0, charMax - 3)}...`
              : promoTitle;

          let IconComponent;
          switch (promo.icon) {
            case 'read':
              IconComponent = IconRead;
              break;
            case 'watch':
              IconComponent = IconWatch;
              break;
            case 'look':
              IconComponent = IconLook;
              break;
            default:
              throw new Error(`Icon type ${promo.icon} not found.`);
          }

          return (
            <div key={promo.url} css={[ngInlinePromo]}>
              <SmartLink
                to={promo.url}
                target={promo.openInNewTab ? '_blank' : null}
                title={promoTitle}
              >
                <div css={ngInlinePromoImage}>
                  <Image
                    entity={promo.imageEntity}
                    aspect={{
                      mobile: '2x3',
                      tablet: '2x3',
                      small_desktop: '3x2',
                      large_desktop: '3x2'
                    }}
                    maxWidths={{
                      mobile: 100,
                      tablet: 145,
                      small_desktop: 213,
                      large_desktop: 213
                    }}
                  />
                </div>
              </SmartLink>
              <div css={ngInlinePromoCont}>
                <div css={ngInlinePromoHeader}>
                  <SmartLink to={promo.url} title={promoTitle}>
                    {promo.header}
                  </SmartLink>
                </div>

                {promo.sponsor && (
                  <div css={ngInlinePromoSponsor}>{promo.sponsor}</div>
                )}

                <div css={ngInlinePromoTitle}>
                  <SmartLink to={promo.url} title={promoTitle}>
                    {truncatedTitle}
                  </SmartLink>
                </div>

                <div css={ngInlinePromoCta}>
                  <SmartLink to={promo.url} title={promoTitle}>
                    <IconComponent />
                    {promo.cta}
                  </SmartLink>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

InlinePromos.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any).isRequired
};

export default InlinePromos;
